import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useContext } from 'react';
import { AlertContext } from '../alerts/AlertContext';

const SymbolDetails = () => {
  const { symbolDetails, selectedContent, livePrices } = useContext(AlertContext);

  if (!selectedContent?.symbol) {
    return null;
  }

  if (!symbolDetails) {
    return (
      <Box mt={2} mb={3} p={2} bgcolor="#f5f5f5" borderRadius={1}>
        Loading symbol details...
      </Box>
    );
  }

  const formatMarketCap = (value) => {
    if (!value) return 'N/A';
    return `₹${value} Cr.`;
  };

  const formatIndianNumber = (num, withDecimals = false) => {
    if (!num) return '0';
    const formatted = num.toLocaleString('en-IN', {
      maximumFractionDigits: withDecimals ? 2 : 0,
      minimumFractionDigits: withDecimals ? 2 : 0
    });
    return formatted;
  };

  const formatRating = (rating) => {
    if (!rating) return null;
    
    let ratingText = rating.Rating === '(EMPTY)' ? 'N/A' : rating.Rating;
    if (rating.RatingPrev && rating.RatingPrev.trim()) {
      const cleanPrevRating = rating.RatingPrev.replace(/\r/g, '');
      ratingText += ` (prev: ${cleanPrevRating})`;
    }
    return ratingText;
  };

  const getRatingsDisplay = () => {
    if (!symbolDetails.ratings || symbolDetails.ratings.length === 0) {
      return 'N/A';
    }

    return symbolDetails.ratings
      .map(rating => formatRating(rating))
      .filter(rating => rating)
      .join(', ') || 'N/A';
  };

  const symbol = selectedContent?.nse || selectedContent?.symbol;
  const ltp = livePrices[symbol];

  return (
    <Box mt={2} mb={3} p={2} bgcolor="#f5f5f5" borderRadius={1}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography variant="subtitle2" color="textSecondary">Market Cap</Typography>
          <Typography variant="body1">{formatMarketCap(symbolDetails.market_cap)}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" color="textSecondary">Themes</Typography>
          <Typography variant="body1">
            {symbolDetails.themes?.map(theme => theme.Theme).join(', ') || 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" color="textSecondary">Ratings</Typography>
          <Typography variant="body1">
            {getRatingsDisplay()}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" color="textSecondary">LTP</Typography>
          <Typography variant="body1">
            {ltp ? `₹${formatIndianNumber(ltp, true)}` : 'N/A'}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SymbolDetails;
