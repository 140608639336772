import React, { useState, useEffect, useContext } from 'react';
import { Box, Tabs, Tab, Button, Typography } from '@mui/material';
import axios from 'axios';
import { marked } from 'marked';
import { AlertContext } from '../alerts/AlertContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = {
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 20px',
    color: 'text.secondary',
    textAlign: 'center',
    height: '40%',
    '& svg': {
      fontSize: '48px',
      marginBottom: '16px',
      color: 'grey.400'
    }
  }
};

const TabPanel = () => {
  const { selectedContent, symbolDetails } = useContext(AlertContext);
  const [activeTab, setActiveTab] = useState('news');
  const [tabData, setTabData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedSummaries, setExpandedSummaries] = useState(new Set());

  const fetchTabData = async (symbol, tabType) => {
    if (!symbol || isLoading) return;
    setIsLoading(true);

    let urls = [];
    switch (tabType) {
      case 'news':
        urls = [
          `${API_BASE_URL}/get_news?query=${encodeURIComponent(symbol)}&news_type=IMPORTANT`,
          `${API_BASE_URL}/get_news?query=${encodeURIComponent(symbol)}&news_type=NOT_IMPORTANT`
        ];
        break;
      case 'concalls':
        urls = [`${API_BASE_URL}/search_concalls_company?query=${encodeURIComponent(symbol)}`];
        break;
      case 'announcements':
        urls = [`${API_BASE_URL}/get_announcements?search=${encodeURIComponent(symbol)}`];
        break;
    }

    try {
      const responses = await Promise.all(urls.map(url => axios.get(url)));
      let items = [];
      if (tabType === 'news') {
        responses.forEach(response => items = items.concat(response.data.news_items));
      } else if (tabType === 'concalls') {
        items = responses[0].data.results;
      } else if (tabType === 'announcements') {
        responses.forEach(response => items = items.concat(response.data.announcements));
      }
      setTabData(items);
    } catch (error) {
      console.error(`Error fetching ${tabType}:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedContent) {
      const symbol = selectedContent.nse || selectedContent.symbol;
      if (symbol) {
        fetchTabData(symbol, activeTab);
      }
    }
  }, [selectedContent, activeTab]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const generateSummary = async (announcementId) => {
    try {
      await axios.post(`${API_BASE_URL}/generate_announcement_summary/${announcementId}`);
      const symbol = selectedContent.nse || selectedContent.symbol;
      if (symbol) {
        fetchTabData(symbol, activeTab);
      }
    } catch (error) {
      console.error('Error generating summary:', error);
    }
  };

  const renderContent = () => {
    if (isLoading) return <Box p={2}>Loading...</Box>;

    if (!selectedContent) {
      return (
        <Box sx={styles.emptyState}>
          <Box fontSize="1.2rem" fontWeight="500" mb={1}>
            No Symbol Selected
          </Box>
          <Box fontSize="0.875rem" color="text.secondary" textAlign="center">
            Please select a news / announcement item or search for a stock to see more details
          </Box>
        </Box>
      );
    }

    if (!symbolDetails) {
      return (
        <Box sx={styles.emptyState}>
          <Box fontSize="1.2rem" fontWeight="500" mb={1}>
            Invalid Symbol
          </Box>
          <Box fontSize="0.875rem" color="text.secondary" textAlign="center">
            Please select a valid stock
          </Box>
        </Box>
      );
    }

    if (!tabData || tabData.length === 0) {
      const emptyMessages = {
        news: 'No news articles found for this company',
        concalls: 'No earnings call transcripts available',
        announcements: 'No announcements found for this company'
      };

      return (
        <Box sx={styles.emptyState}>
          <Box fontSize="1rem" fontWeight="500">
            {emptyMessages[activeTab]}
          </Box>
          <Box fontSize="0.875rem" mt={1} color="text.secondary">
            Try selecting a different tab or check back later
          </Box>
        </Box>
      );
    }

    if (activeTab === 'announcements') {
      return tabData.map((announcement, index) => (
        <Box key={index} className="item" p={2} borderBottom={1} borderColor="grey.300">
          <Box fontWeight="bold">{announcement.title}</Box>
          <Box fontSize="0.9em" color="text.secondary">
            {new Date(announcement.date).toLocaleString('en-US', { 
              day: 'numeric', 
              month: 'long', 
              year: 'numeric', 
              hour: '2-digit', 
              minute: '2-digit', 
              hour12: false 
            })}
          </Box>
          <Box mt={1} display="flex" gap={2} alignItems="center">
            {announcement.attachment && (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                component="a"
                href={announcement.attachment}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Attachment
              </Button>
            )}
            {announcement.summary_of_summaries ? (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => {
                  setExpandedSummaries(prev => {
                    const newSet = new Set(prev);
                    if (newSet.has(announcement._id)) {
                      newSet.delete(announcement._id);
                    } else {
                      newSet.add(announcement._id);
                    }
                    return newSet;
                  });
                }}
              >
                {expandedSummaries.has(announcement._id) ? 'Collapse Summary' : 'Expand Summary'}
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                disabled={announcement.isGeneratingSummary}
                onClick={() => generateSummary(announcement._id)}
              >
                {announcement.isGeneratingSummary ? 'Please wait... Generating Summary' : 'Generate Summary'}
              </Button>
            )}
          </Box>
          {announcement.summary_of_summaries && expandedSummaries.has(announcement._id) && (
            <Box mt={2} p={2} bgcolor="#f5f5f5" borderRadius={1}>
              <div dangerouslySetInnerHTML={{ 
                __html: marked.parse(announcement.summary_of_summaries) 
              }} />
            </Box>
          )}
        </Box>
      ));
    }

    return tabData.map((item, index) => (
      <Box key={index} className="item" p={2} borderBottom={1} borderColor="grey.300">
        {activeTab === 'concalls' ? (
          <>
            <Box fontWeight="bold">{item.symbol}</Box>
            <Box fontSize="0.9em" color="text.secondary">
              {item.quarter}
              <span style={{ marginLeft: 24 }}>Analysed: {item.analysed}</span>
            </Box>
            <Box dangerouslySetInnerHTML={{ 
              __html: item.completion_response ? marked.parse(item.completion_response) : "No analysis available." 
            }} />
          </>
        ) : (
          <>
            <Box fontWeight="bold">{item.title || item.name}</Box>
            <Box fontSize="0.9em" color="text.secondary">
              {new Date(item.date).toLocaleString('en-US', { 
                day: 'numeric', 
                month: 'long', 
                year: 'numeric', 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: false 
              })}
              {item.source && <span style={{ marginLeft: 24 }}>{item.source}</span>}
            </Box>
            {item.headline && <Box mt={1}>{item.headline}</Box>}
          </>
        )}
      </Box>
    ));
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      {selectedContent?.symbol && (
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab value="news" label="News" />
          <Tab value="concalls" label="Concalls" />
          <Tab value="announcements" label="Announcements" />
        </Tabs>
      )}
      <Box flex={1} overflow="auto">
        {renderContent()}
      </Box>
    </Box>
  );
};

export default TabPanel;
