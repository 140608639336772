import React, { useState, useEffect, useRef, useContext } from 'react';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  Divider, 
  Typography,
  CircularProgress,
  Autocomplete,
  TextField,
  Button,
  Grid
} from '@mui/material';
import axios from 'axios';
import { AlertProvider, AlertContext } from '../components/alerts/AlertContext';
import { DashboardProvider } from '../components/dashboard/DashboardContext';
import SymbolDetails from '../components/dashboard/SymbolDetails';
import TabPanel from '../components/dashboard/TabPanel';
import PositionsPanel from '../components/dashboard/PositionsPanel';
import { getUserIdFromToken } from '../utils/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = {
  listItem: {
    padding: '12px 16px',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    }
  },
  sectionHeader: {
    padding: '12px 16px',
    backgroundColor: '#f8f9fa',
    borderBottom: '1px solid #e0e0e0',
    fontWeight: 600,
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  alertValue: {
    fontSize: '0.9rem',
    color: '#1976d2',
    fontWeight: 500,
  },
  searchField: {
    margin: '12px 16px',
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#fff'
    }
  },
  tableContainer: {
    maxHeight: 'calc(100% - 48px)',
    '& .MuiTableCell-root': {
      padding: '8px 16px',
      fontSize: '0.875rem',
    },
    '& .MuiTableCell-head': {
      fontWeight: 700,
    },
    '& .MuiTableHead-root': {
      position: 'sticky',
      top: 0,
      backgroundColor: '#fff',
      zIndex: 1,
    }
  },
  positionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px',
    backgroundColor: '#f8f9fa',
    borderBottom: '1px solid #e0e0e0',
    height: '48px',
    '& .title': {
      fontWeight: 600,
    }
  },
  screenerButton: {
    fontSize: '0.875rem',
    backgroundColor: '#2e7d32',
    color: 'white',
    padding: '6px 12px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '8px',
    '&:hover': {
      backgroundColor: '#1b5e20',
    }
  }
};

const AlertsContent = () => {
  const { 
    setSelectedContent,
    setSymbolDetails,
    setLivePrices,
    enabledAccounts,
    fetchEnabledAccounts
  } = useContext(AlertContext);
  const [alerts60d, setAlerts60d] = useState([]);
  const [alerts5d, setAlerts5d] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [allSymbols, setAllSymbols] = useState([]);
  const [filteredSymbols, setFilteredSymbols] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [newAlertsCount, setNewAlertsCount] = useState({ alerts60d: 0, alerts5d: 0 });
  const [isLoadingPositions, setIsLoadingPositions] = useState(false);
  const seenIds = useRef({ alerts60d: new Set(), alerts5d: new Set() });
  const autocompleteRef = useRef(null);
  const alerts60dRef = useRef(null);
  const alerts5dRef = useRef(null);

  const compareAlerts = (newAlerts, type) => {
    // Find alerts with IDs we haven't seen before
    const newOnes = newAlerts.filter(alert => !seenIds.current[type].has(alert.id));
    
    // Add all IDs to seen set
    newAlerts.forEach(alert => seenIds.current[type].add(alert.id));
    
    console.log(`${type}: Found ${newOnes.length} new alerts out of ${newAlerts.length} total`);
    return newOnes;
  };

  const fetchAlerts = async (isInitial = false) => {
    try {
      if (isInitial) {
        setIsInitialLoading(true);
        // Clear seen IDs on initial load
        seenIds.current = { alerts60d: new Set(), alerts5d: new Set() };
      } else {
        setIsRefreshing(true);
      }

      const [response60d, response5d] = await Promise.all([
        axios.get(`${API_BASE_URL}/get_w52_60d`),
        axios.get(`${API_BASE_URL}/get_w52_5d`)
      ]);

      // Add all IDs to seen set on initial load
      if (isInitial) {
        response60d.data.forEach(alert => seenIds.current.alerts60d.add(alert.id));
        response5d.data.forEach(alert => seenIds.current.alerts5d.add(alert.id));
      } else {
        const newAlerts60d = compareAlerts(response60d.data, 'alerts60d');
        const newAlerts5d = compareAlerts(response5d.data, 'alerts5d');

        // Only update counts if user is scrolled down and there are actually new alerts
        if (alerts60dRef.current && alerts60dRef.current.scrollTop > 0 && newAlerts60d.length > 0) {
          setNewAlertsCount(prev => ({
            ...prev,
            alerts60d: newAlerts60d.length
          }));
        }
        if (alerts5dRef.current && alerts5dRef.current.scrollTop > 0 && newAlerts5d.length > 0) {
          setNewAlertsCount(prev => ({
            ...prev,
            alerts5d: newAlerts5d.length
          }));
        }
      }
      
      setAlerts60d(response60d.data);
      setAlerts5d(response5d.data);
    } catch (error) {
      console.error('Error fetching alerts:', error);
      setError('Failed to fetch alerts');
    } finally {
      setIsInitialLoading(false);
      setIsRefreshing(false);
    }
  };

  const handleScroll = (listRef, alertType) => {
    if (listRef.current && listRef.current.scrollTop === 0) {
      setNewAlertsCount(prev => ({
        ...prev,
        [alertType]: 0
      }));
    }
  };

  useEffect(() => {
    fetchAlerts(true);
    fetchEnabledAccounts(); // Fetch enabled accounts when component mounts
    // Set up auto-refresh every 6 seconds
    const interval = setInterval(() => fetchAlerts(false), 6000);
    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    window.location.href = '/login';  
  };

  const handleSymbolSelect = async (symbol) => {
    try {
      setIsLoadingPositions(true);
      const token = localStorage.getItem('userToken');
      if (!token) throw new Error('No authentication token found');
      
      const headers = { 'Authorization': `Bearer ${token}` };
      const userId = getUserIdFromToken(token);

      // Fetch enabled accounts if not already loaded
      if (!enabledAccounts || enabledAccounts.length === 0) {
        const enabledAccountsResponse = await axios.get(
          `${API_BASE_URL}/user/enabled-accounts/${userId}`, 
          { headers }
        );
        const accounts = enabledAccountsResponse.data.enabledAccounts || [];
        if (!accounts || accounts.length === 0) {
          setError('No accounts enabled for this user');
          return;
        }
      }

      const [symbolResponse, priceResponse] = await Promise.all([
        axios.get(`${API_BASE_URL}/get_symbol_details/${symbol}`),
        axios.get(`${API_BASE_URL}/get_live_price/${symbol}`)
      ]);

      setSelectedContent({ symbol });
      setSymbolDetails(symbolResponse.data);
      setLivePrices(prev => ({
        ...prev,
        [symbol]: priceResponse.data.LTP
      }));
      setSelectedSymbol(symbol);
    } catch (error) {
      console.error('Error fetching symbol data:', error);
      setError(error.response?.data?.error || error.message);
      setSelectedContent(null);
      setSymbolDetails(null);
      setSelectedSymbol(null);
    } finally {
      setIsLoadingPositions(false);
    }
  };

  const clearSelection = () => {
    setSelectedContent(null);
    setSymbolDetails(null);
    setSelectedSymbol(null);
    setSearchQuery('');
    if (autocompleteRef.current) {
      autocompleteRef.current.value = '';
    }
  };

  const renderAlertsList = (alerts, title, listRef, alertType) => (
    <Box height="100%" overflow="auto" ref={listRef} onScroll={() => handleScroll(listRef, alertType)}>
      <List>
        <ListItem sx={styles.sectionHeader}>
          <ListItemText primary={<strong>{title}</strong>} />
        </ListItem>
        {newAlertsCount[alertType] > 0 && (
          <ListItem 
            button 
            onClick={() => {
              if (listRef.current) {
                listRef.current.scrollTop = 0;
                setNewAlertsCount(prev => ({ ...prev, [alertType]: 0 }));
              }
            }}
            sx={{
              bgcolor: '#1976d2',
              color: 'white',
              '&:hover': {
                bgcolor: '#1565c0',
              },
              position: 'sticky',
              top: '48px',
              zIndex: 1,
              justifyContent: 'center',
            }}
          >
            <Typography variant="body2">
              {newAlertsCount[alertType]} new alert{newAlertsCount[alertType] > 1 ? 's' : ''} available
            </Typography>
          </ListItem>
        )}
        {isInitialLoading ? (
          <Box p={2} display="flex" justifyContent="center">
            <CircularProgress size={24} />
          </Box>
        ) : alerts.length === 0 ? (
          <Box p={2} textAlign="center">
            <Typography color="text.secondary">No alerts available</Typography>
          </Box>
        ) : (
          alerts.map((alert, index) => (
            <ListItem 
              button 
              key={`${alert.Company}-${index}`}
              onClick={() => handleSymbolSelect(alert.Company)}
              sx={styles.listItem}
            >
              <ListItemText
                primary={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1">{alert.Company}</Typography>
                    <Typography sx={styles.alertValue}>
                      ₹{alert['52W High']} ({alert['52W High Date']})
                    </Typography>
                  </Box>
                }
                secondary={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2" color="text.secondary">
                      All Time High: ₹{alert['All Time High']}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {new Date(alert['Last Updated']).toLocaleTimeString('en-GB', { timeZone: 'GMT' })}
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          ))
        )}
      </List>
    </Box>
  );

  useEffect(() => {
    const fetchAllSymbols = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/all_symbols`);
        setAllSymbols(response.data.symbols);
        setFilteredSymbols(response.data.symbols);
      } catch (error) {
        console.error('Error fetching symbols:', error);
      }
    };

    fetchAllSymbols();
  }, []);

  return (
    <Box display="flex" height="100vh" overflow="hidden" bgcolor="#f0f2f5">
      {/* Left Column - Alerts */}
      <Box width="25%" borderRight={1} borderColor="divider" overflow="hidden" display="flex" flexDirection="column">
        <Box flex={1} minHeight={0}>
          {renderAlertsList(alerts60d, '52 Week High (60 days)', alerts60dRef, 'alerts60d')}
        </Box>
        <Box flex={1} minHeight={0}>
          {renderAlertsList(alerts5d, '52 Week High (5 days)', alerts5dRef, 'alerts5d')}
        </Box>
      </Box>

      {/* Middle Column - Symbol Details */}
      <Box flex={1} overflow="hidden" sx={{ backgroundColor: '#fff' }}>
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Box sx={styles.searchField}>
              <Autocomplete
                ref={autocompleteRef}
                options={filteredSymbols}
                value={selectedSymbol}
                onChange={(event, newValue) => {
                  if (newValue) {
                    handleSymbolSelect(newValue);
                  }
                }}
                onInputChange={(event, value) => {
                  setSearchQuery(value);
                  if (searchTimeout) clearTimeout(searchTimeout);
                  setSearchTimeout(setTimeout(() => {
                    const filtered = allSymbols.filter(symbol =>
                      symbol.toLowerCase().includes(value.toLowerCase())
                    );
                    setFilteredSymbols(filtered.slice(0, 100));
                  }, 300));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search for a stock..."
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Box>
          </Box>
          {selectedSymbol && (
            <Box mr={2}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={clearSelection}
                sx={{
                  textTransform: 'none',
                  fontSize: '0.875rem'
                }}
              >
                Clear
              </Button>
            </Box>
          )}
        </Box>
        {selectedSymbol && (
          <Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <Typography variant="h6">{selectedSymbol}</Typography>
              <Button
                onClick={() => window.open(`https://www.screener.in/company/${selectedSymbol}/`)}
                sx={styles.screenerButton}
              >
                View on Screener
              </Button>
            </Box>
            {isLoadingPositions ? (
              <Box p={2} display="flex" justifyContent="center">
                <CircularProgress size={24} />
              </Box>
            ) : (
              <>
                <SymbolDetails />
                <TabPanel />
              </>
            )}
          </Box>
        )}
      </Box>

      {/* Right Column - Positions */}
      <Box width="37.5%" overflow="hidden" sx={{ backgroundColor: '#fff' }}>
        <Box sx={{ 
          position: 'absolute', 
          top: 8, 
          right: 16, 
          zIndex: 2 
        }}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleLogout}
            sx={{
              textTransform: 'none',
              fontSize: '0.875rem'
            }}
          >
            Logout
          </Button>
        </Box>
        <Box height="50%" overflow="hidden">
          <Box sx={styles.positionHeader}>
            <Typography className="title">
              Cash Positions {selectedSymbol ? `- ${selectedSymbol}` : ''}
            </Typography>
          </Box>
          <Box sx={{ height: 'calc(100% - 48px)', overflow: 'hidden' }}>
            <PositionsPanel type="cash" />
          </Box>
        </Box>
        <Box height="50%" overflow="hidden">
          <Box sx={styles.positionHeader}>
            <Typography className="title">
              F&O Positions {selectedSymbol ? `- ${selectedSymbol}` : ''}
            </Typography>
          </Box>
          <Box sx={{ height: 'calc(100% - 48px)', overflow: 'hidden' }}>
            <PositionsPanel type="fno" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Alerts = () => (
  <DashboardProvider>
    <AlertProvider>
      <AlertsContent />
    </AlertProvider>
  </DashboardProvider>
);

export default Alerts;
