import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button
} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { AlertContext } from '../alerts/AlertContext';
import shortNameMapping from '../../helpers/short_name.json';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const styles = {
  tableContainer: {
    maxHeight: '100%',
    '& .MuiTableCell-root': {
      padding: '8px 16px',
    },
  },
  noPositionsBox: {
    p: 4,
    textAlign: 'center',
    color: 'text.secondary',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '200px',
    backgroundColor: '#f5f5f5'
  },
  positionHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px',
    backgroundColor: '#f8f9fa',
    borderBottom: '1px solid #e0e0e0',
    height: '48px',
    '& .title': {
      fontWeight: 600,
    }
  }
};

const PositionsPanel = ({ type }) => {
  const { selectedContent, enabledAccounts } = useContext(AlertContext);
  const [positions, setPositions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [noPositionsMessage, setNoPositionsMessage] = useState('');
  const [accountsWithoutPositions, setAccountsWithoutPositions] = useState([]);

  const formatIndianNumber = (num, withDecimals = false) => {
    if (!num && num !== 0) return '0';
    const formatted = num.toLocaleString('en-IN', {
      maximumFractionDigits: withDecimals ? 2 : 0,
      minimumFractionDigits: withDecimals ? 2 : 0
    });
    return formatted;
  };

  const extractUnderlyingSymbol = (symbol) => {
    const match = symbol.match(/^([A-Z]+)/);
    return match ? match[1] : symbol;
  };

  const calculateExposure = (position) => {
    const qty = position.total_quantity_remaining || 0;
    let price = 0;
    
    if (type === 'cash') {
      price = position.LTP || 0;
    } else {
      const underlyingSymbol = extractUnderlyingSymbol(position.symbol);
      price = position.UnderlyingLTP || 0;
    }
    
    return qty * price;
  };

  const getLTP = (position) => {
    if (type === 'cash') {
      return position.LTP || 0;
    } else {
      const underlyingSymbol = extractUnderlyingSymbol(position.symbol);
      return position.UnderlyingLTP || 0;
    }
  };

  const loadPositions = async (resetPage = true) => {
    if (isLoading || !selectedContent?.symbol) return;
    
    setIsLoading(true);
    try {
      const token = localStorage.getItem('userToken');
      if (!token) throw new Error('No authentication token found');
      
      const headers = { 'Authorization': `Bearer ${token}` };
      const currentPage = resetPage ? 1 : page;
      
      const url = `${API_BASE_URL}/${type}_positions_by_symbol/${selectedContent.symbol}`;
      const params = { page: currentPage };
      
      const response = await axios.get(url, { headers, params });
      
      const newPositions = response.data.data || [];
      
      // Only calculate accounts without positions for cash type and when there are some positions
      if (type === 'cash' && newPositions.length > 0 && enabledAccounts && enabledAccounts.length > 0) {
        const accountsWithPositions = new Set(newPositions.map(pos => pos.AccountName));
        const missingAccounts = enabledAccounts.filter(
          account => !accountsWithPositions.has(account.AccountName)
        ).map(account => account.Account);
        setAccountsWithoutPositions(missingAccounts);
      } else {
        setAccountsWithoutPositions([]);
      }

      setPositions(resetPage ? newPositions : [...positions, ...newPositions]);
      setHasMore(response.data.has_next || false);
      setPage(currentPage + 1);
      
      if (newPositions.length === 0) {
        setNoPositionsMessage(`No ${type} positions found for ${selectedContent.symbol}`);
      }
    } catch (error) {
      console.error(`Failed to load ${type} positions:`, error);
      setError(`Failed to load ${type} positions: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedContent?.symbol) {
      setPage(1);
      setPositions([]);
      setHasMore(true);
      loadPositions(true);
    } else {
      setPositions([]);
      setNoPositionsMessage('Select a symbol to view positions');
    }
  }, [selectedContent?.symbol, enabledAccounts]);

  const loadMorePositions = () => loadPositions(false);

  if (isLoading && positions.length === 0) {
    return (
      <Box p={2} textAlign="center">
        Loading positions...
      </Box>
    );
  }

  if (error) {
    return (
      <Box p={2} color="error.main">
        {error}
      </Box>
    );
  }

  if (!positions.length) {
    return (
      <Box 
        sx={{
          p: 4,
          textAlign: 'center',
          color: 'text.secondary',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '200px',
          backgroundColor: '#f5f5f5'
        }}
      >
        <Typography variant="h6" color="text.secondary" gutterBottom>
          No Positions Found
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {noPositionsMessage}
        </Typography>
      </Box>
    );
  }

  return (
    <Box id={`${type}Scroll`} sx={{ height: '100%', overflow: 'auto' }}>
      {type === 'cash' && accountsWithoutPositions.length > 0 && (
        <Box 
          p={2} 
          sx={{
            backgroundColor: '#f8f9fa',
            borderBottom: '1px solid #e0e0e0'
          }}
        >
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Cash positions not available in:
          </Typography>
          <Box sx={{ color: 'text.secondary', pl: 2 }}>
            {accountsWithoutPositions.join(', ')}
          </Box>
        </Box>
      )}
      <InfiniteScroll
        dataLength={positions.length}
        next={loadMorePositions}
        hasMore={hasMore}
        loader={<Box p={2} textAlign="center">Loading more positions...</Box>}
        scrollableTarget={`${type}Scroll`}
      >
        <TableContainer component={Paper} sx={styles.tableContainer}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Symbol</TableCell>
                <TableCell align="right">Exposure</TableCell>
                <TableCell>Account Name</TableCell>
                <TableCell>Strategy</TableCell>
                <TableCell align="right">Qty</TableCell>
                <TableCell align="right">Avg Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {positions.map((position, index) => {
                const shortNameEntry = shortNameMapping.find(
                  entry => entry.AccountName === position.AccountName
                );
                const shortName = shortNameEntry ? shortNameEntry.Account : position.AccountName;
                const ltp = getLTP(position);

                return (
                  <TableRow key={`${type}-${position._id || index}`}>
                    <TableCell>{position.symbol}</TableCell>
                    <TableCell align="right">
                      {formatIndianNumber(calculateExposure(position))}
                    </TableCell>
                    <TableCell>{shortName}</TableCell>
                    <TableCell>{position.StgName}</TableCell>
                    <TableCell align="right">{formatIndianNumber(position.total_quantity_remaining)}</TableCell>
                    <TableCell align="right">
                      {formatIndianNumber(position.average_price, true)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
    </Box>
  );
};

export default PositionsPanel;
