import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Autocomplete, Modal, Alert } from '@mui/material';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ResearchModal = ({ open, onClose, onAdd, symbols }) => {
  const [researchText, setResearchText] = useState('');
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleAdd = async () => {
    try {
      setIsSubmitting(true);
      setError(null);

      const response = await axios.post(
        `${API_BASE_URL}/add_research`,
        {
          text: researchText,
          symbol: selectedSymbol
        },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('userToken')}`
          }
        }
      );

      if (response.data.success) {
        const newItem = {
          _id: response.data.id,
          title: researchText,
          date: new Date().toISOString(),
          source: 'internal_research',
          gpt_classification: 'IMPORTANT',
          nse: selectedSymbol,
          symbol: selectedSymbol
        };
        
        onAdd(newItem);
        setResearchText('');
        setSelectedSymbol(null);
        onClose();
      }
    } catch (error) {
      console.error('Error adding research:', error);
      setError(error.response?.data?.error || 'Failed to add research');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setError(null);
    setResearchText('');
    setSelectedSymbol(null);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="research-modal-title"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 1,
      }}>
        <Typography id="research-modal-title" variant="h6" component="h2" mb={3}>
          Add Research
        </Typography>
        
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        
        <Autocomplete
          options={symbols}
          getOptionLabel={(option) => option}
          value={selectedSymbol}
          onChange={(_, newValue) => setSelectedSymbol(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Symbol (Optional)"
              variant="outlined"
              fullWidth
              size="small"
              sx={{ mb: 2 }}
            />
          )}
        />
        
        <TextField
          multiline
          rows={4}
          value={researchText}
          onChange={(e) => setResearchText(e.target.value)}
          label="Research Notes"
          variant="outlined"
          fullWidth
          sx={{ mb: 3 }}
        />
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button 
            variant="outlined" 
            onClick={handleClose}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleAdd}
            disabled={!researchText.trim() || isSubmitting}
          >
            {isSubmitting ? 'Adding...' : 'Add'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default React.memo(ResearchModal); 