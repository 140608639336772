import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { getUserIdFromToken } from '../../utils/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [selectedContent, setSelectedContent] = useState(null);
  const [symbolDetails, setSymbolDetails] = useState(null);
  const [enabledAccounts, setEnabledAccounts] = useState([]);
  const [livePrices, setLivePrices] = useState({});
  const [error, setError] = useState(null);

  const fetchEnabledAccounts = async () => {
    try {
      const token = localStorage.getItem('userToken');
      if (!token) throw new Error('No authentication token found');
      
      const headers = { 'Authorization': `Bearer ${token}` };
      const userId = getUserIdFromToken(token);
      if (!userId) throw new Error('Invalid user token');

      const response = await axios.get(
        `${API_BASE_URL}/user/enabled-accounts/${userId}`, 
        { headers }
      );
      setEnabledAccounts(response.data.enabledAccounts || []);
    } catch (error) {
      setError(error.message);
    }
  };

  const value = {
    selectedContent,
    setSelectedContent,
    symbolDetails,
    setSymbolDetails,
    enabledAccounts,
    setEnabledAccounts,
    livePrices,
    setLivePrices,
    error,
    setError,
    fetchEnabledAccounts
  };

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};
