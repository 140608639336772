import React, { useState, useEffect, useRef } from 'react';
import { Box, List, ListItem, ListItemText, Divider, Tabs, Tab, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Autocomplete, Grid, Typography, Button, RadioGroup, FormControlLabel, Radio, Link } from '@mui/material';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { marked } from 'marked';
import shortNameMapping from '../helpers/short_name.json';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ResearchModal from '../components/ResearchModal';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const App = () => {
  const [newsItems, setNewsItems] = useState([]);
  const [announcementItems, setAnnouncementItems] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [activeTab, setActiveTab] = useState('news');
  const [tabData, setTabData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [cashPositions, setCashPositions] = useState([]);
  const [fnoPositions, setFnoPositions] = useState([]);
  const [cashPage, setCashPage] = useState(1);
  const [fnoPage, setFnoPage] = useState(1);
  const [cashHasMore, setCashHasMore] = useState(true);
  const [fnoHasMore, setFnoHasMore] = useState(true);
  const [isCashLoading, setIsCashLoading] = useState(false);
  const [isFnoLoading, setIsFnoLoading] = useState(false);
  const [newsPage, setNewsPage] = useState(1);
  const [announcementsPage, setAnnouncementsPage] = useState(1);
  const [hasMoreNews, setHasMoreNews] = useState(true);
  const [hasMoreAnnouncements, setHasMoreAnnouncements] = useState(true);
  const [isNewsLoading, setIsNewsLoading] = useState(false);
  const [isAnnouncementsLoading, setIsAnnouncementsLoading] = useState(false);
  const [latestNewsItems, setLatestNewsItems] = useState([]);
  const [latestAnnouncementItems, setLatestAnnouncementItems] = useState([]);
  const [newItemsCount, setNewItemsCount] = useState({ news: 0, announcements: 0 });
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [symbolDetails, setSymbolDetails] = useState(null);
  const [newsScrollPosition, setNewsScrollPosition] = useState(0);
  const [announcementsScrollPosition, setAnnouncementsScrollPosition] = useState(0);
  const [error, setError] = useState(null);
  const [positionsLoading, setPositionsLoading] = useState(false);
  const [positionsError, setPositionsError] = useState({ cash: null, fno: null });
  const [noPositionsMessage, setNoPositionsMessage] = useState({ cash: '', fno: '' });
  const [initialPositionsLoaded, setInitialPositionsLoaded] = useState(false);
  const [enabledAccounts, setEnabledAccounts] = useState([]);
  const [livePrices, setLivePrices] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [allSymbols, setAllSymbols] = useState([]);
  const [filteredSymbols, setFilteredSymbols] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const autocompleteRef = useRef(null);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [pendingRequests, setPendingRequests] = useState(0);
  const [expandedSummaries, setExpandedSummaries] = useState(new Set());
  const [portfolioSymbols, setPortfolioSymbols] = useState([]);
  const [newsFilter, setNewsFilter] = useState('ALL');
  const [eventCalendarItems, setEventCalendarItems] = useState([]);
  const eventCalendarInitialized = useRef(false);
  const [isResearchModalOpen, setIsResearchModalOpen] = useState(false);
  const [hasResearchAccess, setHasResearchAccess] = useState(false);

  const activeRequests = useRef(new Set());
  const priceUpdateTimeout = useRef(null);

  const extractUnderlyingSymbol = (symbol) => {
    // For F&O symbols like "NIFTY23SEP20200CE", extract the underlying "NIFTY"
    // This is a simple example - adjust the regex based on your actual F&O symbol format
    const match = symbol.match(/^([A-Z]+)/);
    return match ? match[1] : symbol;
  };

  const styles = {
    listItem: {
      padding: '12px 16px',
      '&:hover': {
        backgroundColor: '#f5f5f5',
      }
    },
    listItemTitle: {
      fontSize: '0.95rem',
      fontWeight: 500,
      lineHeight: 1.4,
      marginBottom: '4px'
    },
    listItemMeta: {
      fontSize: '0.8rem',
      color: '#666'
    },
    sectionHeader: {
      padding: '12px 16px',
      backgroundColor: '#f8f9fa',
      borderBottom: '1px solid #e0e0e0',
      fontWeight: 600,
      position: 'sticky',
      top: 0,
      zIndex: 1,
      backgroundColor: '#f8f9fa'
    },
    announcementsHeader: {
      padding: '12px 16px',
      backgroundColor: '#f8f9fa',
      borderBottom: '1px solid #e0e0e0',
      fontWeight: 600,
      position: 'sticky',
      top: 0,
      zIndex: 1,
      backgroundColor: '#f8f9fa'
    },
    tabContent: {
      '& .item': {
        padding: '16px',
        borderBottom: '1px solid #e0e0e0',
        '&:hover': {
          backgroundColor: '#f8f9fa'
        }
      }
    },
    searchField: {
      margin: '12px 16px',
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#fff'
      }
    },
    clearButton: {
      fontSize: '0.875rem',
      backgroundColor: 'primary.main',
      color: 'white',
      padding: '6px 12px',
      borderRadius: '4px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'primary.dark',
      }
    },
    screenerButton: {
      fontSize: '0.875rem',
      backgroundColor: '#2e7d32', // MUI's green[800]
      color: 'white',
      padding: '6px 12px',
      borderRadius: '4px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#1b5e20', // MUI's green[900]
      }
    },
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '40px 20px',
      color: 'text.secondary',
      textAlign: 'center',
      height: '40%',
      '& svg': {
        fontSize: '48px',
        marginBottom: '16px',
        color: 'grey.400'
      }
    },
    tableContainer: {
      maxHeight: 'calc(100% - 48px)',
      '& .MuiTableCell-root': {
        padding: '8px 16px',
        fontSize: '0.875rem',
      },
      '& .MuiTableCell-head': {
        fontWeight: 700,
      },
      '& .MuiTableHead-root': {
        position: 'sticky',
        top: 0,
        backgroundColor: '#fff',
        zIndex: 1,
      }
    },
    positionHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '12px 16px',
      backgroundColor: '#f8f9fa',
      borderBottom: '1px solid #e0e0e0',
      height: '48px',
      '& .title': {
        fontWeight: 600,
      }
    },
    aiTagLabel: {
      backgroundColor: '#f44336',
      color: 'white',
      padding: '2px 6px',
      borderRadius: '4px',
      fontSize: '0.7rem',
      marginLeft: '8px',
      fontWeight: 'bold',
    },
  };

  const formatDateTime = (dateString, dateOnly = false) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', dateOnly ? { 
      day: '2-digit', 
      month: '2-digit', 
      year: '2-digit'
    } : { 
      day: '2-digit', 
      month: '2-digit', 
      year: '2-digit', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit', 
      hour12: false 
    });
  };

  const clearSelection = () => {
    setSelectedContent(null);
    setSymbolDetails(null);
    setTabData([]);
    setActiveTab('news');
    setCashPage(1);
    setFnoPage(1);
    setCashHasMore(true);
    setFnoHasMore(true);
    setError(null);
    setPositionsError({ cash: null, fno: null });
    setIsCashLoading(false);
    setIsFnoLoading(false);
    
    // Clear search-related states
    setSelectedSymbol(null);
    setSearchQuery('');
    
    // Clear positions without reloading them
    setCashPositions([]);
    setFnoPositions([]);
    
  };

  const formatDateOnly = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', { 
      day: '2-digit', 
      month: '2-digit', 
      year: '2-digit'
    });
  };

  const formatIndianNumber = (num, withDecimals = false) => {
    if (!num) return '0';
    const formatted = num.toLocaleString('en-IN', {
      maximumFractionDigits: withDecimals ? 2 : 0,
      minimumFractionDigits: withDecimals ? 2 : 0
    });
    return formatted;
  };

  const logPositionUpdate = (type, positions, source) => {
    console.log(`${type} positions updated:`, {
      count: positions.length,
      source,
      firstItem: positions[0]
    });
  };

  const fetchPositions = async (type, symbol = null) => {
    const isLoading = type === 'cash' ? isCashLoading : isFnoLoading;
    if (isLoading) return;
    
    try {
      const setLoading = type === 'cash' ? setIsCashLoading : setIsFnoLoading;
      setLoading(true);
      setPositionsError(prev => ({ ...prev, [type]: null }));
      
      const token = localStorage.getItem('userToken');
      if (!token) throw new Error('No authentication token found');
      
      const headers = { 'Authorization': `Bearer ${token}` };
      const userId = getUserIdFromToken(token);
      if (!userId) throw new Error('Invalid user token');

      const enabledAccountsResponse = await axios.get(
        `${API_BASE_URL}/user/enabled-accounts/${userId}`, 
        { headers }
      );
      const enabledAccounts = enabledAccountsResponse.data.enabledAccounts || [];
      setEnabledAccounts(enabledAccounts);

      if (!enabledAccounts || enabledAccounts.length === 0) {
        setNoPositionsMessage(prev => ({
          ...prev,
          [type]: 'No accounts enabled for this user'
        }));
        if (type === 'cash') {
          setCashPositions([]);
          setCashHasMore(false);
        } else {
          setFnoPositions([]);
          setFnoHasMore(false);
        }
        return;
      }

      // Use type-specific search and page values
      const page = type === 'cash' ? cashPage : fnoPage;
      
      let endpoint = symbol 
        ? `${API_BASE_URL}/${type}_positions_by_symbol/${symbol}`
        : `${API_BASE_URL}/all_${type}_positions`;
      
      const response = await axios.get(endpoint, {
        headers,
        params: { page }
      });

      const positions = response.data.data || [];
      
      // Filter positions by enabled accounts and add shortened account names
      const filteredPositions = positions.filter(pos => 
        enabledAccounts.some(acc => acc.AccountName === pos.AccountName)
      ).map(pos => {
        const account = enabledAccounts.find(acc => acc.AccountName === pos.AccountName);
        return {
          ...pos,
          ShortAccountName: account ? account.Account : pos.AccountName
        };
      });
      
      // Update only the relevant type's positions
      if (type === 'cash') {
        setCashPositions(filteredPositions);
        setCashHasMore(response.data.has_next || false);
        if (filteredPositions.length === 0) {
          setNoPositionsMessage(prev => ({
            ...prev,
            cash: `No cash positions found${symbol ? ` for ${symbol}` : ''}`
          }));
        } else {
          setNoPositionsMessage(prev => ({
            ...prev,
            cash: ''
          }));
        }
      } else {
        setFnoPositions(filteredPositions);
        setFnoHasMore(response.data.has_next || false);
        if (filteredPositions.length === 0) {
          setNoPositionsMessage(prev => ({
            ...prev,
            fno: `No F&O positions found${symbol ? ` for ${symbol}` : ''}`
          }));
        } else {
          setNoPositionsMessage(prev => ({
            ...prev,
            fno: ''
          }));
        }
      }

      // Clear no positions message if we have positions
      if (filteredPositions.length > 0) {
        setNoPositionsMessage(prev => ({
          ...prev,
          [type]: ''
        }));
      }
    } catch (error) {
      handleError(error, type);
    } finally {
      const setLoading = type === 'cash' ? setIsCashLoading : setIsFnoLoading;
      setLoading(false);
    }
  };

  const handleError = (error, type = null) => {
    const errorMessage = error.response?.data?.error || 
                        error.response?.data?.details || 
                        error.message;
    
    if (type) {
      setPositionsError(prev => ({
        ...prev,
        [type]: `Failed to load ${type} positions: ${errorMessage}`
      }));
      
      if (type === 'cash') {
        setCashPositions([]);
        setCashHasMore(false);
      } else {
        setFnoPositions([]);
        setFnoHasMore(false);
      }
    } else {
      setError(`Error: ${errorMessage}`);
    }
  };

  const loadMoreCash = async () => {
    if (!isCashLoading && cashHasMore) {
      setIsCashLoading(true);
      try {
        const nextPage = cashPage + 1;
        const response = await axios.get(`${API_BASE_URL}/all_cash_positions`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('userToken')}` },
          params: { page: nextPage }
        });
        
        const newPositions = response.data.data || [];
        setCashPositions(prev => [...prev, ...newPositions]);
        setCashHasMore(response.data.has_next || false);
        setCashPage(nextPage);
      } catch (error) {
        handleError(error, 'cash');
      } finally {
        setIsCashLoading(false);
      }
    }
  };

  const loadMoreFno = async () => {
    if (!isFnoLoading && fnoHasMore) {
      setIsFnoLoading(true);
      try {
        const nextPage = fnoPage + 1;
        const response = await axios.get(`${API_BASE_URL}/all_fno_positions`, {
          headers: { 'Authorization': `Bearer ${localStorage.getItem('userToken')}` },
          params: { page: nextPage }
        });
        
        const newPositions = response.data.data || [];
        setFnoPositions(prev => [...prev, ...newPositions]);
        setFnoHasMore(response.data.has_next || false);
        setFnoPage(nextPage);
      } catch (error) {
        handleError(error, 'fno');
      } finally {
        setIsFnoLoading(false);
      }
    }
  };

  const handleItemClick = async (item) => {
    setSelectedContent(item);
    const symbol = item.nse || item.symbol;
    if (symbol && isValidSymbol(symbol)) {
      try {
        // Fetch symbol details first
        const symbolResponse = await axios.get(`${API_BASE_URL}/get_symbol_details/${symbol}`);
        setSymbolDetails(symbolResponse.data);
        
        // Then fetch tab data
        fetchTabData(symbol, activeTab);
      } catch (error) {
        console.error('Error fetching symbol data:', error);
        setSymbolDetails(null); // Reset on error
      }
    }
  };

  const fetchTabData = async (symbol, tabType) => {
    if (!symbol || isLoading) return;
    setIsLoading(true);

    let urls = [];
    switch (tabType) {
      case 'news':
        urls = [
          `${API_BASE_URL}/get_news?query=${encodeURIComponent(symbol)}&news_type=IMPORTANT`,
          `${API_BASE_URL}/get_news?query=${encodeURIComponent(symbol)}&news_type=NOT_IMPORTANT`
        ];
        break;
      case 'concalls':
        urls = [`${API_BASE_URL}/search_concalls_company?query=${encodeURIComponent(symbol)}`];
        break;
      case 'announcements':
        urls = [`${API_BASE_URL}/get_announcements?search=${encodeURIComponent(symbol)}`];
        break;
    }

    try {
      const responses = await Promise.all(urls.map(url => axios.get(url)));
      let items = [];
      if (tabType === 'news') {
        responses.forEach(response => items = items.concat(response.data.news_items));
      } else if (tabType === 'concalls') {
        items = responses[0].data.results;
      } else if (tabType === 'announcements') {
        responses.forEach(response => items = items.concat(response.data.announcements));
      }
      setTabData(items);
    } catch (error) {
      console.error(`Error fetching ${tabType}:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidSymbol = (symbol) => {
    return Boolean(symbol && allSymbols.includes(symbol));
  };

  const renderTabContent = () => {
    if (isLoading) return <Box p={2}>Loading...</Box>;

    if (!selectedContent) {
      return (
        <Box sx={styles.emptyState}>
          <Box fontSize="1.2rem" fontWeight="500" mb={1}>
            No Symbol Selected
          </Box>
          <Box fontSize="0.875rem" color="text.secondary" textAlign="center">
            Please select a news / announcement item or search for a stock to see more details
          </Box>
        </Box>
      );
    }

    if (!isValidSymbol(selectedContent.nse || selectedContent.symbol)) {
      return (
        <Box sx={styles.emptyState}>
          <Box fontSize="1.2rem" fontWeight="500" mb={1}>
            Invalid Symbol
          </Box>
          <Box fontSize="0.875rem" color="text.secondary" textAlign="center">
            Please select a valid stock
          </Box>
        </Box>
      );
    }

    if (!tabData || tabData.length === 0) {
      const emptyMessages = {
        news: 'No news articles found for this company',
        concalls: 'No earnings call transcripts available',
        announcements: 'No announcements found for this company'
      };

      return (
        <Box sx={styles.emptyState}>
          <Box fontSize="1rem" fontWeight="500">
            {emptyMessages[activeTab]}
          </Box>
          <Box fontSize="0.875rem" mt={1} color="text.secondary">
            Try selecting a different tab or check back later
          </Box>
        </Box>
      );
    }

    if (activeTab === 'announcements') {
      return tabData.map((announcement, index) => (
        <Box key={index} className="item" p={2} borderBottom={1} borderColor="grey.300">
          <Box fontWeight="bold">{announcement.title}</Box>
          <Box fontSize="0.9em" color="text.secondary">
            {new Date(announcement.date).toLocaleString('en-US', { 
              day: 'numeric', 
              month: 'long', 
              year: 'numeric', 
              hour: '2-digit', 
              minute: '2-digit', 
              hour12: false 
            })}
          </Box>
          <Box mt={1} display="flex" gap={2} alignItems="center">
            {announcement.attachment && (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                component="a"
                href={announcement.attachment}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Attachment
              </Button>
            )}
            {announcement.summary_of_summaries ? (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => {
                  setExpandedSummaries(prev => {
                    const newSet = new Set(prev);
                    if (newSet.has(announcement._id)) {
                      newSet.delete(announcement._id);
                    } else {
                      newSet.add(announcement._id);
                    }
                    return newSet;
                  });
                }}
              >
                {expandedSummaries.has(announcement._id) ? 'Collapse Summary' : 'Expand Summary'}
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                disabled={announcement.isGeneratingSummary}
                onClick={() => generateSummary(announcement._id)}
              >
                {announcement.isGeneratingSummary ? 'Please wait... Generating Summary' : 'Generate Summary'}
              </Button>
            )}
          </Box>
          {announcement.summary_of_summaries && expandedSummaries.has(announcement._id) && (
            <Box mt={2} p={2} bgcolor="#f5f5f5" borderRadius={1}>
              <div dangerouslySetInnerHTML={{ 
                __html: marked.parse(announcement.summary_of_summaries) 
              }} />
            </Box>
          )}
        </Box>
      ));
    }

    if (activeTab === 'concalls') {
      // Group concalls by quarter
      const quarterLinks = tabData.reduce((acc, item) => {
        if (!acc[item.quarter]) {
          acc[item.quarter] = item.drive_link;
        }
        return acc;
      }, {});

      return (
        <>
          {/* Quick Links Section */}
          <Box 
            sx={{ 
              p: 2, 
              mb: 2, 
              backgroundColor: '#f8f9fa',
              borderRadius: 1,
              border: '1px solid #e0e0e0'
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 600 }}>
              Quick Links to Concall PDFs
            </Typography>
            <Grid container spacing={1}>
              {Object.entries(quarterLinks).map(([quarter, link]) => (
                <Grid item xs={6} sm={4} key={quarter}>
                  {link ? (
                    <Link
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        color: 'primary.main',
                        textDecoration: 'none',
                        '&:hover': {
                          textDecoration: 'underline'
                        }
                      }}
                    >
                      {quarter}
                    </Link>
                  ) : (
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                    >
                      {quarter} (not available)
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Existing Concalls Content */}
          {tabData.map((item, index) => (
            <Box key={index} className="item" p={2} borderBottom={1} borderColor="grey.300">
              <Box fontWeight="bold">{item.symbol}</Box>
              <Box fontSize="0.9em" color="text.secondary">
                {item.quarter}
                <span style={{ marginLeft: 24 }}>Analysed: {item.analysed}</span>
              </Box>
              <Box dangerouslySetInnerHTML={{ 
                __html: item.completion_response ? marked.parse(item.completion_response) : "No analysis available." 
              }} />
            </Box>
          ))}
        </>
      );
    }

    return tabData.map((item, index) => (
      <Box key={index} className="item" p={2} borderBottom={1} borderColor="grey.300">
        {activeTab === 'concalls' ? (
          <>
            <Box fontWeight="bold">{item.symbol}</Box>
            <Box fontSize="0.9em" color="text.secondary">
              {item.quarter}
              <span style={{ marginLeft: 24 }}>Analysed: {item.analysed}</span>
            </Box>
            <Box dangerouslySetInnerHTML={{ 
              __html: item.completion_response ? marked.parse(item.completion_response) : "No analysis available." 
            }} />
          </>
        ) : (
          <>
            <Box fontWeight="bold" display="flex" alignItems="center">
              {item.title || item.name}
              {item.ai_tagged && item.category === 'COMPANY' && item.nse && (
                <span style={styles.aiTagLabel}>AI_TAGGED</span>
              )}
            </Box>
            <Box fontSize="0.9em" color="text.secondary">
              {new Date(item.date).toLocaleString('en-US', { 
                day: 'numeric', 
                month: 'long', 
                year: 'numeric', 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: false 
              })}
              {item.source && <span style={{ marginLeft: 24 }}>{item.source}</span>}
            </Box>
            {item.headline && <Box mt={1}>{item.headline}</Box>}
            {item.attachment && (
              <Box mt={1}>
                <a href={item.attachment} target="_blank" rel="noopener noreferrer">Attachment</a>
              </Box>
            )}
          </>
        )}
      </Box>
    ));
  };

  const renderEventCalendar = () => {
    // First filter based on selected symbol if any
    let filteredEvents = selectedContent
      ? eventCalendarItems.filter(event => event.symbol === (selectedContent.nse || selectedContent.symbol))
      : eventCalendarItems;

    // Then apply portfolio filter if "Portfolio Only" is selected
    if (newsFilter === 'POSITIONS') {
      filteredEvents = filteredEvents.filter(event => portfolioSymbols.includes(event.symbol));
    }

    if (filteredEvents.length === 0) {
      return (
          <Box
              p={4}
              textAlign="center"
              color="text.secondary"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: "100%",
                backgroundColor: '#f5f5f5'
              }}
          >
            <Typography variant="h6" color="text.secondary" gutterBottom>
              No Events Found
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {selectedContent
                  ? `No upcoming events available for ${selectedContent.nse || selectedContent.symbol}`
                  : newsFilter === 'POSITIONS'
                      ? 'No events found for portfolio symbols'
                      : 'Select a stock to filter events or view all upcoming events'}
            </Typography>
          </Box>
      );
    }

    // Find index of first event that's on or after current date
    const currentDate = new Date().setHours(0, 0, 0, 0);
    const currentEventIndex = filteredEvents.findIndex(event => 
      new Date(event.date).setHours(0, 0, 0, 0) >= currentDate
    );

    return (
        <Box sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <TableContainer
              component={Paper}
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                '& .MuiTable-root': {
                  flex: 1
                }
              }}
              ref={el => {
                if (el && currentEventIndex !== -1 && !eventCalendarInitialized.current) {
                  // Get the table body
                  const tableBody = el.querySelector('tbody');
                  if (tableBody) {
                    // Get all rows pre target
                    const rows = tableBody.querySelectorAll('tr');
                    let actualPosition = 0;

                    // Sum up heights of all rows pre target
                    for (let i = 0; i < currentEventIndex; i++) {
                      if (rows[i]) {
                        actualPosition += rows[i].getBoundingClientRect().height;
                      }
                    }

                    // Add header height
                    const headerHeight = el.querySelector('thead')?.getBoundingClientRect().height || 0;
                    actualPosition += headerHeight;

                    // Scroll to position
                    el.scrollTo({
                      top: Math.max(0, actualPosition - 20),
                      behavior: 'smooth'
                    });

                    eventCalendarInitialized.current = true;
                  }
                }
              }}
          >
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Event</TableCell>
                  <TableCell>Purpose</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredEvents.map((event, index) => (
                    <TableRow
                        key={`event-${index}`}
                        sx={{
                          backgroundColor: new Date(event.date).setHours(0, 0, 0, 0) === currentDate ? '#e3f2fd' : 'inherit'
                        }}
                    >
                      <TableCell>{formatDateTime(event.date, true)}</TableCell>
                      <TableCell>{event.symbol}</TableCell>
                      <TableCell sx={{ maxWidth: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {event.bm_desc}
                      </TableCell>
                      <TableCell>{event.purpose}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
    );
  };

  const SymbolDetailsSection = () => {
    if (!symbolDetails) return <Box mt={2} mb={3} p={2} bgcolor="#f5f5f5" borderRadius={1}>Loading symbol details...</Box>;

    const formatMarketCap = (value) => {
      if (!value) return 'N/A';
      return `₹${value} Cr.`;
    };

    const formatRating = (rating) => {
      if (!rating) return null;
      
      let ratingText = rating.Rating === '(EMPTY)' ? 'N/A' : rating.Rating;
      if (rating.RatingPrev && rating.RatingPrev.trim()) {
        const cleanPrevRating = rating.RatingPrev.replace(/\r/g, '');
        ratingText += ` (prev: ${cleanPrevRating})`;
      }
      return ratingText;
    };

    const getRatingsDisplay = () => {
      if (!symbolDetails.ratings || symbolDetails.ratings.length === 0) {
        return 'N/A';
      }

      return symbolDetails.ratings
        .map(rating => formatRating(rating))
        .filter(rating => rating) 
        .join(', ') || 'N/A';
    };

    const symbol = selectedContent?.nse || selectedContent?.symbol;
    const ltp = livePrices[symbol];

    return (
      <Box mt={2} mb={3} p={2} bgcolor="#f5f5f5" borderRadius={1}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="textSecondary">Market Cap</Typography>
            <Typography variant="body1">{formatMarketCap(symbolDetails.market_cap)}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="textSecondary">Themes</Typography>
            <Typography variant="body1">
              {symbolDetails.themes?.map(theme => theme.Theme).join(', ') || 'N/A'}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="textSecondary">Ratings</Typography>
            <Typography variant="body1">
              {getRatingsDisplay()}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2" color="textSecondary">LTP</Typography>
            <Typography variant="body1">
              {ltp ? `₹${formatIndianNumber(ltp, true)}` : 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const calculatePositionValue = (position) => {
    const quantity = position.total_quantity_remaining || 0;
    const price = position.LTP || 0;
    return quantity * price;
  };

  const calculateFnOPositionValue = (position) => {
    const quantity = position.total_quantity_remaining || 0;
    const underlyingPrice = position.UnderlyingLTP || 0;
    return quantity * underlyingPrice;
  };

  const renderPositions = (type) => {
    const positions = type === 'cash' ? cashPositions : fnoPositions;
    const isLoading = type === 'cash' ? isCashLoading : isFnoLoading;
    const error = positionsError[type];
    const noPositionsMsg = noPositionsMessage[type];
    const hasMore = type === 'cash' ? cashHasMore : fnoHasMore;

    if (isLoading && positions.length === 0) {
      return (
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
            Loading positions...
          </Box>
      );
    }

    if (error) {
      return (
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'error.main'
          }}>
            {error}
          </Box>
      );
    }

    if (!positions.length) {
      return (
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5'
          }}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              No Positions Found
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              {selectedContent
                  ? (noPositionsMsg || `No ${type.toUpperCase()} positions available for ${selectedContent.nse || selectedContent.symbol}`)
                  : 'Please select a news / announcement item or search for a stock to see positions'}
            </Typography>
          </Box>
      );
    }

    return (
        <Box id={`${type}Scroll`} sx={{ height: '100%', overflow: 'auto' }}>
          <InfiniteScroll
              dataLength={positions.length}
              next={() => type === 'cash' ? loadMoreCash() : loadMoreFno()}
              hasMore={hasMore}
              loader={<Box p={2} textAlign="center">Loading more positions...</Box>}
              scrollableTarget={`${type}Scroll`}
          >
            <TableContainer component={Paper} sx={styles.tableContainer}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Symbol</TableCell>
                    <TableCell align="right">Exposure</TableCell>
                    <TableCell>Account Name</TableCell>
                    <TableCell>Strategy</TableCell>
                    <TableCell align="right">Qty</TableCell>
                    <TableCell align="right">Avg Price</TableCell>
                    {type === 'fno' && <TableCell align="right">Underlying Price</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {positions.map((position, index) => {
                    // Find matching short name from JSON
                    const shortNameEntry = shortNameMapping.find(
                        entry => entry.AccountName === position.AccountName
                    );
                    const shortName = shortNameEntry ? shortNameEntry.Account : position.AccountName;

                    return (
                        <TableRow key={`${type}-${position._id || index}`}>
                          <TableCell>{position.symbol}</TableCell>
                          <TableCell align="right">
                            {formatIndianNumber(
                                type === 'cash'
                                    ? ((position.total_quantity_remaining || 0) * (livePrices[position.symbol] || position.LTP || 0))
                                    : ((position.total_quantity_remaining || 0) * (livePrices[extractUnderlyingSymbol(position.symbol)] || position.UnderlyingLTP || 0))
                            )}
                          </TableCell>
                          <TableCell>{shortName}</TableCell>
                          <TableCell>{position.StgName}</TableCell>
                          <TableCell align="right">{formatIndianNumber(position.total_quantity_remaining)}</TableCell>
                          <TableCell align="right">
                            {formatIndianNumber(position.average_price, true)}
                          </TableCell>
                          {type === 'fno' && (
                              <TableCell align="right">
                                {formatIndianNumber(position.UnderlyingLTP, true)}
                              </TableCell>
                          )}
                        </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </InfiniteScroll>
        </Box>
    );
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    window.location.href = '/login';  
  };

  const renderMissingPositionsAccounts = () => {
    if (!selectedContent || !enabledAccounts.length) return null;
    
    // If there are no cash positions at all, don't show the missing accounts list
    if (cashPositions.length === 0) return null;

    // Get accounts that have cash positions only
    const accountsWithCashPositions = new Set(cashPositions.map(pos => pos.AccountName));
    
    // Filter enabled accounts where cash position is not present
    const missingAccounts = enabledAccounts.filter(account => 
      !accountsWithCashPositions.has(account.AccountName)
    );

    if (!missingAccounts.length) return null;

    return (
      <Box sx={{ padding: '16px', borderBottom: '1px solid #e0e0e0' }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 600, marginBottom: 1 }}>
          Cash Position not present in:
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          flexWrap: 'wrap', 
          gap: '8px',
        }}>
          {missingAccounts.map((account) => (
            <Box
              key={account.Account}
              sx={{
                backgroundColor: '#f5f5f5',
                padding: '4px 8px',
                borderRadius: '4px',
                fontSize: '0.875rem',
              }}
            >
              {account.Account}
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  const rightColumn = (
      <Box width="37.5%" overflow="hidden" sx={{
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'  // Ensure full height
      }}>
        <Box sx={{
          position: 'absolute',
          top: 8,
          right: 16,
          zIndex: 2
        }}>
          <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={handleLogout}
              sx={{
                textTransform: 'none',
                fontSize: '0.875rem'
              }}
          >
            Logout
          </Button>
        </Box>

        {/* Cash Positions Section */}
        <Box sx={{
          height: '30%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0  // Important for flex child
        }}>
          <Box sx={styles.positionHeader}>
        <span className="title">
          Cash Positions {selectedContent && `- ${selectedContent.nse || selectedContent.symbol}`}
        </span>
          </Box>
          <Box sx={{
            flex: 1,
            overflow: 'hidden',
            position: 'relative' // For empty state positioning
          }}>
            {renderPositions('cash')}
          </Box>
        </Box>

        {renderMissingPositionsAccounts()}

        {/* F&O Positions Section */}
        <Box sx={{
          height: '30%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0  // Important for flex child
        }}>
          <Box sx={styles.positionHeader}>
        <span className="title">
          F&O Positions {selectedContent && `- ${selectedContent.nse || selectedContent.symbol}`}
        </span>
          </Box>
          <Box sx={{
            flex: 1,
            overflow: 'hidden',
            position: 'relative' // For empty state positioning
          }}>
            {renderPositions('fno')}
          </Box>
        </Box>

        {/* Event Calendar Section */}
        <Box sx={{
          height: '40%',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 0  // Important for flex child
        }}>
          <Box sx={styles.positionHeader}>
            <span className="title">Event Calendar</span>
          </Box>
          <Box sx={{
            flex: 1,
            overflow: 'hidden',
            position: 'relative', // For empty state positioning
            display: 'flex',
            flexDirection: 'column'
          }}>
            {renderEventCalendar()}
          </Box>
        </Box>
      </Box>
  );

  const loadMoreNews = async () => {
    if (isNewsLoading || !hasMoreNews) return;

    setIsNewsLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/get_news?news_type=IMPORTANT&page=${newsPage + 1}&per_page=250&query=&market_cap_category=all`
      );
      setNewsItems(prev => [...prev, ...response.data.news_items]);
      setHasMoreNews(response.data.has_more);
      setNewsPage(prev => prev + 1);
    } catch (error) {
      console.error('Error loading more news:', error);
    } finally {
      setIsNewsLoading(false);
    }
  };

  const loadMoreAnnouncements = async () => {
    if (isAnnouncementsLoading || !hasMoreAnnouncements) return;

    setIsAnnouncementsLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/get_announcements?market_cap_category=all&page=${announcementsPage + 1}&per_page=250`
      );
      setAnnouncementItems(prev => [...prev, ...response.data.announcements]);
      setHasMoreAnnouncements(response.data.has_more);
      setAnnouncementsPage(prev => prev + 1);
    } catch (error) {
      console.error('Error loading more announcements:', error);
    } finally {
      setIsAnnouncementsLoading(false);
    }
  };

  const handleNewsScroll = (event) => {
    setNewsScrollPosition(event.target.scrollTop);
  };

  const handleAnnouncementsScroll = (event) => {
    setAnnouncementsScrollPosition(event.target.scrollTop);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Fetch latest news
      axios.get(`${API_BASE_URL}/get_news?news_type=IMPORTANT&page=1&per_page=250&query=&market_cap_category=all`)
        .then(response => {
          const newItems = response.data.news_items;
          if (newsScrollPosition === 0) {
            // User is at the top, merge directly
            setNewsItems(prev => {
              const existingIds = new Set(prev.map(item => item._id));
              const uniqueNewItems = newItems.filter(item => !existingIds.has(item._id));
              return [...uniqueNewItems, ...prev];
            });
          } else {
            // User is not at the top, show banner
            setLatestNewsItems(newItems);
            // Compare with current items to get actual count of new items
            setNewsItems(prev => {
              const existingIds = new Set(prev.map(item => item._id));
              const uniqueNewItems = newItems.filter(item => !existingIds.has(item._id));
              setNewItemsCount(prev => ({ ...prev, news: uniqueNewItems.length }));
              return prev;
            });
          }
        })
        .catch(error => console.error('Error fetching latest news:', error));

      // Fetch latest announcements
      axios.get(`${API_BASE_URL}/get_announcements?market_cap_category=all&page=1&per_page=250`)
        .then(response => {
          const newItems = response.data.announcements;
          if (announcementsScrollPosition === 0) {
            // User is at the top, merge directly
            setAnnouncementItems(prev => {
              const existingIds = new Set(prev.map(item => item._id));
              const uniqueNewItems = newItems.filter(item => !existingIds.has(item._id));
              return [...uniqueNewItems, ...prev];
            });
          } else {
            // User is not at the top, show banner
            setLatestAnnouncementItems(newItems);
            // Compare with current items to get actual count of new items
            setAnnouncementItems(prev => {
              const existingIds = new Set(prev.map(item => item._id));
              const uniqueNewItems = newItems.filter(item => !existingIds.has(item._id));
              setNewItemsCount(prev => ({ ...prev, announcements: uniqueNewItems.length }));
              return prev;
            });
          }
        })
        .catch(error => console.error('Error fetching latest announcements:', error));
    }, 10000);

    return () => clearInterval(interval);
  }, [newsScrollPosition, announcementsScrollPosition]);

  const loadInitialData = async () => {
    if (initialPositionsLoaded) return;
    
    try {
      setIsLoading(true);
      setError(null);
      
      const token = localStorage.getItem('userToken');
      if (!token) throw new Error('No authentication token found');
      
      const headers = { 'Authorization': `Bearer ${token}` };
      const userId = getUserIdFromToken(token);
      
      // Add portfolio symbols fetch to the initial requests
      const [
        newsResponse,
        announcementsResponse,
        enabledAccountsResponse,
        portfolioSymbolsResponse,
        eventCalendarResponse,
      ] = await Promise.all([
        axios.get(`${API_BASE_URL}/get_news?news_type=IMPORTANT&page=1&per_page=250&query=&market_cap_category=all`),
        axios.get(`${API_BASE_URL}/get_announcements?market_cap_category=all&page=1&per_page=250`),
        axios.get(`${API_BASE_URL}/user/enabled-accounts/${userId}`, { headers }),
        axios.get(`${API_BASE_URL}/get_cash_symbols`, { headers }),
        axios.get(`${API_BASE_URL}/event-calendar`),
      ]);

      // Update news and announcements
      setNewsItems(newsResponse.data.news_items);
      setAnnouncementItems(announcementsResponse.data.announcements);
      setHasMoreNews(newsResponse.data.has_more);
      setHasMoreAnnouncements(announcementsResponse.data.has_more);
      
      const enabledAccounts = enabledAccountsResponse.data.enabledAccounts || [];
      setHasResearchAccess(enabledAccountsResponse.data.research || false);
      
      if (!enabledAccounts.length) {
        setNoPositionsMessage({ cash: 'No accounts enabled for this user', fno: 'No accounts enabled for this user' });
        return;
      }

      // Set portfolio symbols
      setPortfolioSymbols(portfolioSymbolsResponse.data.symbols || []);

      setEnabledAccounts(enabledAccounts);
      setInitialPositionsLoaded(true);
      setIsInitialLoad(false);
      
      setEventCalendarItems(eventCalendarResponse.data || []);
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadInitialData();
  }, []);

  useEffect(() => {
    if (!initialPositionsLoaded) return;
    
    const symbol = selectedContent?.symbol || selectedContent?.nse;
    if (symbol) {
      setCashPage(1);
      setFnoPage(1);
      setCashPositions([]); // Clear existing positions
      setFnoPositions([]); // Clear existing positions
      
      setIsCashLoading(true);
      setIsFnoLoading(true);
      
      Promise.all([
        fetchPositions('cash', symbol),
        fetchPositions('fno', symbol)
      ]).finally(() => {
        setIsCashLoading(false);
        setIsFnoLoading(false);
      });
    } else {
      // Clear positions when no symbol is selected
      setCashPositions([]);
      setFnoPositions([]);
    }
  }, [selectedContent, initialPositionsLoaded]);

  const getUserIdFromToken = (token) => {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = JSON.parse(window.atob(base64));
      return payload.userId;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  const generateUniqueKey = (item, type, index) => {
    // Use multiple properties to ensure uniqueness
    const timestamp = new Date(item.date).getTime();
    const symbol = item.nse || item.symbol || '';
    return `${type}-${item._id || ''}-${symbol}-${timestamp}-${index}`;
  };

  const mergeNewNews = () => {
    const newsScrollElement = document.getElementById('newsScroll');
    setNewsItems(prev => {
      const existingIds = new Set(prev.map(item => item._id));
      const uniqueNewItems = latestNewsItems.filter(item => !existingIds.has(item._id));
      return [...uniqueNewItems, ...prev];
    });
    setLatestNewsItems([]);
    setNewItemsCount(prev => ({ ...prev, news: 0 }));
    if (newsScrollElement) {
      newsScrollElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const mergeNewAnnouncements = () => {
    const announcementsScrollElement = document.getElementById('announcementsScroll');
    setAnnouncementItems(prev => {
      const existingIds = new Set(prev.map(item => item._id));
      const uniqueNewItems = latestAnnouncementItems.filter(item => !existingIds.has(item._id));
      return [...uniqueNewItems, ...prev];
    });
    setLatestAnnouncementItems([]);
    setNewItemsCount(prev => ({ ...prev, announcements: 0 }));
    // Scroll to top
    if (announcementsScrollElement) {
      announcementsScrollElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const fetchLivePrice = async (symbol) => {
    // If request for this symbol is already in progress, skip
    if (activeRequests.current.has(symbol)) return;
    
    try {
      activeRequests.current.add(symbol);
      const response = await axios.get(`${API_BASE_URL}/get_live_price/${symbol}`);
      setLivePrices(prev => ({
        ...prev,
        [symbol]: response.data.LTP
      }));
    } catch (error) {
      console.error('Error fetching live price:', error);
    } finally {
      activeRequests.current.delete(symbol);
    }
  };

  useEffect(() => {
    const refreshPrices = async () => {
      const uniqueSymbols = new Set();
      
      // Get symbols from cash positions
      cashPositions.forEach(pos => uniqueSymbols.add(pos.symbol));
      
      // Get underlying symbols from F&O positions
      fnoPositions.forEach(pos => {
        const underlying = extractUnderlyingSymbol(pos.symbol);
        uniqueSymbols.add(underlying);
      });

      // Always add the currently selected symbol if it exists
      if (selectedContent?.nse || selectedContent?.symbol) {
        uniqueSymbols.add(selectedContent.nse || selectedContent.symbol);
      }

      // Only fetch prices if we have symbols to fetch
      if (uniqueSymbols.size > 0) {
        const promises = Array.from(uniqueSymbols).map(symbol => 
          fetchLivePrice(symbol)
        );

        await Promise.all(promises);
      }
    };

    // Clear any existing timeout
    if (priceUpdateTimeout.current) {
      clearInterval(priceUpdateTimeout.current);
    }

    // Initial fetch
    refreshPrices();

    // Set up new interval only if we have positions or a selected symbol
    if (cashPositions.length > 0 || fnoPositions.length > 0 || selectedContent) {
      priceUpdateTimeout.current = setInterval(refreshPrices, 60000);
    }

    // Cleanup
    return () => {
      if (priceUpdateTimeout.current) {
        clearInterval(priceUpdateTimeout.current);
      }
      // Clear any active requests
      activeRequests.current.clear();
    };
  }, [cashPositions, fnoPositions, selectedContent]);

  const handleSymbolSearch = (query) => {
    // Clear previous timeout
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    // Set new timeout for filtering
    const newTimeout = setTimeout(async () => {
      const filtered = allSymbols.filter(symbol => 
        symbol.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredSymbols(filtered);
    }, 300);

    setSearchTimeout(newTimeout);
  };

  // Clean up search timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
  }, [searchTimeout]);

  const fetchAllSymbols = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/all_symbols`);
      setAllSymbols(response.data.symbols);
      setFilteredSymbols(response.data.symbols);
    } catch (error) {
      console.error('Error fetching symbols:', error);
    }
  };

  useEffect(() => {
    fetchAllSymbols();
  }, []);

  const incrementPendingRequests = () => setPendingRequests(prev => prev + 1);
  const decrementPendingRequests = () => setPendingRequests(prev => Math.max(0, prev - 1));

  useEffect(() => {
    // Add request interceptor
    const requestInterceptor = axios.interceptors.request.use(
      config => {
        incrementPendingRequests();
        return config;
      },
      error => {
        decrementPendingRequests();
        return Promise.reject(error);
      }
    );

    // Add response interceptor
    const responseInterceptor = axios.interceptors.response.use(
      response => {
        decrementPendingRequests();
        return response;
      },
      error => {
        decrementPendingRequests();
        return Promise.reject(error);
      }
    );

    // Cleanup
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  const generateSummary = async (announcementId) => {
    try {
      // Show loading state for this specific announcement
      setTabData(prevItems => prevItems.map(item => 
        item._id === announcementId 
          ? { ...item, isGeneratingSummary: true }
          : item
      ));

      const response = await axios.post(`${API_BASE_URL}/generate_summary`, {
        announcement_id: announcementId
      });

      if (response.status === 202) {
        // Keep the loading state and start polling for the summary
        let attempts = 0;
        const maxAttempts = 30; // 30 attempts * 2 seconds = 60 seconds maximum wait
        
        const checkSummary = async () => {
          try {
            const updatedResponse = await axios.get(`${API_BASE_URL}/get_announcements`, {
              params: {
                search: selectedContent.nse || selectedContent.symbol,
                page: 1,
                per_page: 50
              }
            });
            
            const updatedAnnouncement = updatedResponse.data.announcements.find(
              item => item._id === announcementId
            );
            
            if (updatedAnnouncement?.summary_of_summaries) {
              // Summary is ready
              setExpandedSummaries(prev => new Set([...prev, announcementId]));
              setTabData(updatedResponse.data.announcements);
              return true;
            }
            
            attempts++;
            if (attempts >= maxAttempts) {
              // Give up after max attempts
              console.error('Summary generation timed out');
              setTabData(prevItems => prevItems.map(item => 
                item._id === announcementId 
                  ? { ...item, isGeneratingSummary: false }
                  : item
              ));
              return true;
            }
            
            return false;
          } catch (error) {
            console.error('Error checking summary status:', error);
            return true; // Stop polling on error
          }
        };

        const pollInterval = setInterval(async () => {
          const shouldStop = await checkSummary();
          if (shouldStop) {
            clearInterval(pollInterval);
          }
        }, 2000);

      }
    } catch (error) {
      console.error('Error generating summary:', error);
      // Reset loading state on error
      setTabData(prevItems => prevItems.map(item => 
        item._id === announcementId 
          ? { ...item, isGeneratingSummary: false }
          : item
      ));
    }
  };

  const filterContentByPortfolio = (items) => {
    if (newsFilter === 'ALL') return items;
    
    return items.filter(item => {
      const symbol = item.nse || item.symbol;
      return portfolioSymbols.includes(symbol);
    });
  };

  const handleOpenResearchModal = () => {
    setIsResearchModalOpen(true);
  };

  const handleCloseResearchModal = () => {
    setIsResearchModalOpen(false);
  };

  const handleAddResearch = async (newItem) => {
    try {
      // Prepend the new item to the news list
      setNewsItems(prevItems => [newItem, ...prevItems]);
      handleCloseResearchModal();
    } catch (error) {
      console.error('Error adding research to news list:', error);
    }
  };

  const renderNewsSection = () => (
    <Box height="50%" overflow="auto" id="newsScroll" onScroll={handleNewsScroll}>
      <Box sx={{ 
        position: 'sticky', 
        top: 0, 
        zIndex: 2, 
        backgroundColor: '#fff',
        borderBottom: '1px solid #e0e0e0'
      }}>
        {newItemsCount.news > 0 && (
          <Box 
            p={1} 
            bgcolor="primary.main" 
            color="white" 
            textAlign="center" 
            sx={{ 
              cursor: 'pointer',
              '&:hover': { bgcolor: 'primary.dark' }
            }}
            onClick={mergeNewNews}
          >
            {newItemsCount.news} new items available
          </Box>
        )}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          padding: '8px 16px'
        }}>
          <RadioGroup
            row
            value={newsFilter}
            onChange={(e) => setNewsFilter(e.target.value)}
          >
            <FormControlLabel value="ALL" control={<Radio size="small" />} label="All News" />
            <FormControlLabel value="POSITIONS" control={<Radio size="small" />} label="Portfolio Only" />
          </RadioGroup>
          {hasResearchAccess && (
            <Tooltip title="Add Research">
              <IconButton 
                size="small"
                onClick={handleOpenResearchModal}
                sx={{ ml: 1 }}
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>

      <InfiniteScroll
        dataLength={newsItems.length}
        next={loadMoreNews}
        hasMore={hasMoreNews}
        loader={<Box p={2} textAlign="center">Loading...</Box>}
        scrollableTarget="newsScroll"
      >
        <List>
          <ListItem sx={styles.sectionHeader}>
            <ListItemText primary={<strong>Live News</strong>} />
          </ListItem>
          {filterContentByPortfolio(newsItems).map((news, index) =>  (
            <ListItem 
              button 
              key={generateUniqueKey(news, 'news', index)}
              onClick={() => handleItemClick(news)}
              sx={styles.listItem}
            >
              <ListItemText
                primary={
                  <div style={styles.listItemTitle}>
                    {news.title}
                    {news.ai_tagged && news.category === 'COMPANY' && news.nse && (
                      <span style={styles.aiTagLabel}>AI_TAGGED</span>
                    )}
                  </div>
                }
                secondary={
                  <div style={styles.listItemMeta}>
                    {news.nse || news.symbol} | {formatDateTime(news.date)}
                  </div>
                }
              />
            </ListItem>
          ))}
        </List>
      </InfiniteScroll>
    </Box>
  );

  useEffect(() => {
    // Reset event calendar initialization when filters change
    eventCalendarInitialized.current = false;
  }, [selectedContent, newsFilter]); // Dependencies that should trigger a reset

  return (
    <Box display="flex" height="100vh" sx={{ backgroundColor: '#f5f6f7' }}>
      <Box width="25%" borderRight={1} borderColor="grey.300" display="flex" flexDirection="column" sx={{ backgroundColor: '#fff' }}>
        {error && (
          <Box sx={{ 
            p: 2, 
            bgcolor: '#ffebee', 
            color: '#c62828',
            borderBottom: '1px solid #ef9a9a'
          }}>
            <Typography variant="body2">{error}</Typography>
          </Box>
        )}
        {renderNewsSection()}
        <Divider />
        <Box 
          height="50%" 
          overflow="auto" 
          id="announcementsScroll"
          onScroll={handleAnnouncementsScroll}
        >
          {newItemsCount.announcements > 0 && (
            <Box 
              p={1} 
              bgcolor="primary.main" 
              color="white" 
              textAlign="center" 
              sx={{ 
                cursor: 'pointer',
                position: 'sticky',
                top: 0,
                zIndex: 1,
                '&:hover': { bgcolor: 'primary.dark' }
              }}
              onClick={mergeNewAnnouncements}
            >
              {newItemsCount.announcements} new announcements available
            </Box>
          )}
          <InfiniteScroll
            dataLength={announcementItems.length}
            next={loadMoreAnnouncements}
            hasMore={hasMoreAnnouncements}
            loader={<Box p={2} textAlign="center">Loading...</Box>}
            scrollableTarget="announcementsScroll"
            scrollThreshold={0.8}
            style={{ overflow: 'visible' }}
          >
            <List>
              <ListItem sx={styles.announcementsHeader}>
                <ListItemText primary={<strong>Announcements</strong>} />
              </ListItem>
              {filterContentByPortfolio(announcementItems).map((announcement, index) => (
                <ListItem 
                  button 
                  key={generateUniqueKey(announcement, 'announcement', index)}
                  onClick={() => handleItemClick(announcement)}
                  sx={styles.listItem}
                >
                  <ListItemText
                    primary={<div style={styles.listItemTitle}>{announcement.title}</div>}
                    secondary={
                      <div>
                        <div style={styles.listItemMeta}>
                        {announcement.headline}
                      </div>
                      <div style={styles.listItemMeta}>
                        {announcement.nse || announcement.symbol} | {formatDateTime(announcement.date)}
                      </div>
                      </div>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </InfiniteScroll>
        </Box>
      </Box>
      <Box width="37.5%" borderRight={1} borderColor="grey.300" overflow="auto" sx={{ backgroundColor: '#fff' }}>
        <Box p={3}>
          <Autocomplete
            ref={autocompleteRef}
            options={filteredSymbols}
            getOptionLabel={(option) => option}
            onInputChange={(_, newValue) => {
              setSearchQuery(newValue);
              handleSymbolSearch(newValue);
            }}
            onChange={(_, newValue) => {
              setSelectedSymbol(newValue);
              if (newValue && isValidSymbol(newValue)) {
                const mockItem = {
                  symbol: newValue,
                  nse: newValue
                };
                handleItemClick(mockItem);
              } else {
                setSymbolDetails(null);
              }
            }}
            value={selectedSymbol}
            inputValue={searchQuery}
            blurOnSelect={true}
            selectOnFocus={true}
            handleHomeEndKeys={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Symbol"
                variant="outlined"
                fullWidth
              />
            )}
            sx = {{mb:2}}
          />

          {selectedContent && (
            <Box display="flex" alignItems="center" mb={2}>
              <h2 style={{ margin: 0, fontSize: '1.4rem' }}>
                {selectedContent.nse || selectedContent.symbol}
              </h2>
              <Box 
                component="span" 
                sx={{ ...styles.clearButton, marginLeft: '16px' }}
                onClick={clearSelection}
              >
                Clear Selection
              </Box>
              {isValidSymbol(selectedContent.nse || selectedContent.symbol) && (
                <Box 
                  component="span" 
                  sx={{ ...styles.screenerButton, marginLeft: '16px' }}
                  onClick={() => window.open(`https://www.screener.in/company/${selectedContent.nse || selectedContent.symbol}/`, '_blank')}
                >
                  View on Screener
                </Box>
              )}
            </Box>
          )}

          {selectedContent && isValidSymbol(selectedContent.nse || selectedContent.symbol) && (
            <>
              <SymbolDetailsSection />
              <Tabs 
                value={activeTab} 
                onChange={(_, newValue) => {
                  setActiveTab(newValue);
                  fetchTabData(selectedContent.symbol || selectedContent.nse, newValue);
                }}
                sx={{
                  '& .MuiTab-root': {
                    fontSize: '0.9rem',
                    fontWeight: 500,
                    textTransform: 'none',
                    minWidth: 100
                  }
                }}
              >
                <Tab label="News" value="news" />
                <Tab label="Concalls" value="concalls" />
                <Tab label="Announcements" value="announcements" />
              </Tabs>
            </>
          )}
          {renderTabContent()}
        </Box>
      </Box>
      {rightColumn}
      
      {pendingRequests > 0 && (
        <Box
          sx={{
            position: 'fixed',
            top: '16px',
            right: '88px',
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: '4px',
            padding: '8px 16px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          <CircularProgress size={20} sx={{ marginRight: 1 }} />
          <Typography variant="body2" color="text.secondary">
            Loading...
          </Typography>
        </Box>
      )}
      <ResearchModal 
        open={isResearchModalOpen}
        onClose={handleCloseResearchModal}
        onAdd={handleAddResearch}
        symbols={filteredSymbols}
      />
    </Box>
  );
};

export default App;